import { createNamespacedHelpers } from 'vuex';
import api from './api';

export const { mapState, mapActions } = createNamespacedHelpers('actionCentre');

const initialState = () => ({
  loading: false,
  connections: {
    filters: {
      direction: 'all',
      status: 'pending',
    },
  },
  remediations: {
    loaded: false,
    requests: [],
    filters: {
      direction: 'received',
      status: 'awaiting_response',
    },
    counts: {
      received: {
        total: 0,
        awaiting_response: 0,
        awaiting_review: 0,
        completed: 0,
      },
      sent: {
        total: 0,
        awaiting_response: 0,
        awaiting_review: 0,
        completed: 0,
      },
    },
  },
});

const mutations = {
  SET_CONNECTION_REQUEST_FILTERS(state, filters) {
    state.connections.filters = filters;
  },
  GET_REMEDIATION_REQUESTS(state, data) {
    state.remediations.filters.status = data.status;
    state.remediations.filters.direction = data.direction;
    state.remediations.requests = data.results;
    state.remediations.counts.received = data.received;
    state.remediations.counts.sent = data.sent;
    state.loading = false;
    state.remediations.loaded = true;
  },
  START_LOADING(state) {
    state.loading = true;
  },
  CLEAR_STATE(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  setConnectionRequestsFilters({ commit }, filters) {
    commit('SET_CONNECTION_REQUEST_FILTERS', filters);
  },
  async setRemediationRequestsFilters({ commit }, filters) {
    commit('START_LOADING');
    const res = await api.getRemediationRequests(filters.direction, filters.status);
    res.data.status = filters.status;
    res.data.direction = filters.direction;
    commit('GET_REMEDIATION_REQUESTS', res.data);
  },
  clearActionCentre({ commit }) {
    commit('CLEAR_STATE');
  },
};

// TODO: replace with composables (0.5 days)
const actionCentreStore = {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
};

export default actionCentreStore;
