import { initialState } from './mutations';

const filtersChanged = (state) => {
  for (const [k, v] of Object.entries(initialState().assessmentFilters)) {
    if (Array.isArray(v)) {
      if (JSON.stringify(v.sort()) !== JSON.stringify(state.assessmentFilters[k].slice().sort()))
        return true;
    } else if (v !== state.assessmentFilters[k]) return true;
  }
  return false;
};

const evidenceFiltersChanged = (state) => {
  for (const [k, v] of Object.entries(initialState().evidenceFilters)) {
    if (Array.isArray(v)) {
      if (JSON.stringify(v.sort()) !== JSON.stringify(state.evidenceFilters[k].slice().sort()))
        return true;
    } else if (v !== state.evidenceFilters[k]) return true;
  }
  return false;
};

export const getters = {
  filtersChanged,
  evidenceFiltersChanged,
};
