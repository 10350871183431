export default {
  en: {
    settings: {
      title: 'Products and Services',
      description:
        'Manage the products and services you offer your clients, and associate answers to these products.',
      archivedTitle: 'Archived Products',
      add: 'Add Product or Service',
      questions: '{count} question | {count} questions',
      clients: '{count} client | {count} clients',
      placeholderTitle: 'No products or services',
      placeholderDescription: 'Add a product or service and it will be visible here',
      archiveProduct: 'Archive product',
    },
    addProductModal: {
      success: 'Product added successfully',
      error: 'Failed to add product. Please try again later.',
      title: 'Add a Product or Service',
      description:
        'You will be able to associate this label with product-specific answers, and allow clients who use this product to access these answers',
      addButton: 'Add Product or Service',
      productName: 'Product or Service Name',
      productDescription: 'Description of the Product or Service',
      productClients: 'What Existing Clients Use This Product?',
      cancel: 'Cancel',
      add: 'Add',
    },
    editProductModal: {
      success: 'Product updated successfully',
      error: 'Failed to update product. Please try again later.',
      title: 'Edit Product or Service',
    },
    archiveProductModal: {
      title: 'Are you sure you want to archive this product?',
      listTitle: 'Archiving this product will:',
      listOne: 'Remove any client connections to this product',
      listTwo: 'Archive any answers associated with this product',
      listThree: 'Archive any related discussions you might be having with your client',
      unarchiveWarning: 'You will not be able to unarchive this product in the future',
      cancel: 'Cancel',
      archive: 'Archive Product',
      success: 'Product archived successfully',
      error: 'Failed to archive product. Try again later.',
    },
  },
};
