import { createNamespacedHelpers } from 'vuex';

import { state, mutations } from './mutations';
import { actions } from './actions';

export const { mapState, mapActions } = createNamespacedHelpers('search');

// TODO: replace with composables (0.5 days)
const searchStore = {
  namespaced: true,
  actions,
  state,
  mutations,
};

export default searchStore;
