import { createNamespacedHelpers } from 'vuex';
import api from './api';

export const { mapState, mapActions, mapGetters } = createNamespacedHelpers('answer');

const initialState = () => ({
  answersBySupplierIDAndControlID: {},
  scopingAnswersBySupplierIDAndDomainID: {},
});

const mutations = {
  SET_SUPPLIER_ASSESSMENT(state, { supplierID, data }) {
    state.answersBySupplierIDAndControlID[supplierID] = data.answers.reduce((obj, ans) => {
      obj[ans.controlID] = ans;
      return obj;
    }, {});
    state.scopingAnswersBySupplierIDAndDomainID[supplierID] = data.scopingAnswers.reduce(
      (obj, ans) => {
        obj[ans.domainID] = ans;
        return obj;
      },
      {},
    );
  },
};

const actions = {
  fetchSupplierAssessment: async ({ commit }, supplierID) => {
    const res = await api.fetchSupplierAssessment(supplierID);
    commit('SET_SUPPLIER_ASSESSMENT', { supplierID, data: res.data });
  },
};

const getters = {
  getSupplierAnswer: (state, getters, rootState, rootGetters) => (supplierID, controlID) => {
    const domainsByID = rootGetters['framework/domainsByID'];
    const controlsByID = rootGetters['framework/controlsByID'];

    const control = controlsByID[controlID];
    const domain = domainsByID[control?.domainID];

    const supplierAnswers = state.answersBySupplierIDAndControlID?.[supplierID];
    const supplierScopingAnswers = state.scopingAnswersBySupplierIDAndDomainID?.[supplierID];

    let oos = false;

    const answer = supplierAnswers?.[controlID];

    if (domain?.scopingQuestion) {
      const scopingAnswer = supplierScopingAnswers?.[control?.domainID];
      if (scopingAnswer && !scopingAnswer.answer) {
        oos = true;
      }
    }

    return {
      ...answer,
      outOfScope: oos,
    };
  },
};

// TODO: is this store even used? (1 hour)
// (replace with composables if it is - more than 1 hour tho!)
export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
};
