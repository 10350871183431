import { request } from '@/api/request';
import type { RemediationRequests } from '@/types/models/remediationrequest';

export type OrgInfo = {
  name: string;
  id: string;
  logoUrl: string;
  companyNumber: string;
  country: string;
  sector: string;
  tradingNames: string[];
  approvalStatus: string;
};

export type ByDomain = {
  percentageCompliant: number;
  numberNoncompliant: number;
};

export type Stats = {
  compliance: number;
  complianceByDomain: Record<string, ByDomain>;
  numPoliciesApplied: number;
  remediationResponses: number;
  remediations: number;
};

export type ClientInfoResponse = {
  connectionID: string;
  orgInfo: OrgInfo | null;
  stats: Stats | null;
  initialAssessmentComplete: boolean;
  assessmentProgress: number;
  lastActiveAt: number | null;
  managed: boolean;
};

const getClientInfo = (id: string) => request<ClientInfoResponse>('GET', `/client/${id}`);

export interface Override {
  controlID: string;
  createdAt: Date;
  reason: string;
  type: string;
}

export interface Answer {
  answer: string;
  answerID: string;
  controlID: string;
  documentIDs: string[];
  modifiedAt: string;
  notes: string;
  notApplicable: boolean;
  unconfirmedControlUpdate: boolean;
}

export interface ScopingAnswer {
  id: string;
  answer: boolean;
  domainID: string;
  unconfirmedScopingControlUpdate: boolean;
}

export type ClientAssessmentResponse = {
  answers: Answer[];
  overrides: { [key: string]: Override };
  remediation: RemediationRequests;
  policyRequirements: { [key: string]: { [key: string]: any } };
  scopingAnswers: ScopingAnswer[];
  complianceByControlID: { [key: string]: boolean };
};

const getClientAssessment = (id: string) =>
  request<ClientAssessmentResponse>('GET', `/client/${id}/assessment`);

export default {
  getClientInfo,
  getClientAssessment,
};
