import type { RouteRecordRaw } from 'vue-router';
import Permission from '@/composables/permissions/permissions';

export default [
  {
    path: 'answer-search',
    meta: {
      permissions: [Permission.ClientsSuppliersView],
    },
    children: [
      {
        path: '',
        name: 'client-answer-search',
        component: () => import('./AnswerLookup.vue'),
      },
    ],
  },
] satisfies RouteRecordRaw[];
