import { createNamespacedHelpers } from 'vuex';
import api from './api';

export const { mapState, mapActions, mapGetters, mapMutations } =
  createNamespacedHelpers('federatedInvites');

const initialState = () => ({
  byID: {}, // map of invite ID to invite object
  allIDs: [], // invite IDs, ordered from newest sent to oldest sent
});

const fetchInvites = async ({ commit }) => {
  const res = await api.fetchInvites();
  commit('SET_ALL_INVITES', res.data);
};

const sendInvite = async ({ commit }, { organisationName, contactEmail }) => {
  const res = await api.sendInvite(organisationName, contactEmail);
  commit('ADD_INVITE', res.data);
};

const cancelInvite = async ({ commit }, id) => {
  const res = await api.cancelInvite(id);
  commit('SET_INVITE', res.data);
};

const actions = {
  fetchInvites,
  sendInvite,
  cancelInvite,
};

const mutations = {
  SET_ALL_INVITES(state, invites) {
    state.byID = invites.reduce((obj, invite) => {
      obj[invite.id] = invite;
      return obj;
    }, {});
    state.allIDs = invites.map((invite) => invite.id);
  },
  ADD_INVITE(state, invite) {
    state.byID[invite.id] = invite;
    state.allIDs.push(invite.id);
  },
  SET_INVITE(state, invite) {
    state.byID[invite.id] = invite;
  },
};

// TODO: replace with composables (1 day)
export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
};
