import type { RouteRecordRaw } from 'vue-router';
import { requireFlag } from '@/routes/guards/flag';
import communitiesMessageBoardRoutes from '@/modules/communitiesMessageBoard/routes';
import Permission from '@/composables/permissions/permissions';
import { FeatureFlag } from '@/composables/feature-flags';

export default [
  {
    path: 'communities',
    beforeEnter: [requireFlag(FeatureFlag.Communities)],
    meta: {
      permissions: [Permission.ClientsCommunitiesMessageboardView],
    },
    children: [
      {
        path: '',
        name: 'client-community-onboarding',
        component: () => import('./JoinCommunity.vue'),
      },
      {
        path: ':communityId',
        children: [
          {
            path: '',
            name: 'client-community',
            component: () => import('./CommunityPage.vue'),
            props: true,
          },
          ...communitiesMessageBoardRoutes,
        ],
      },
    ],
  },
] satisfies RouteRecordRaw[];
