// Vuex & Store Entity Imports
import { createNamespacedHelpers } from 'vuex';
import api from './api';

// Parent Store Module
export const { mapState, mapMutations, mapGetters, mapActions } =
  createNamespacedHelpers('profileSharingPublic');

const initialState = () => ({
  displayEvidenceModal: false,
  searchValue: '',
  data: undefined,
});

const mutations = {
  SET_DISPLAY_EVIDENCE_MODAL(state, val) {
    state.displayEvidenceModal = val;
  },
  SET_SEARCH_VALUE(state, val) {
    state.searchValue = val;
  },
  SET_DATA(state, val) {
    state.data = val;
  },
};

const actions = {
  async fetchProfileSharingLink({ commit }, linkID) {
    const res = await api.fetchProfileSharingLink(linkID);
    commit('SET_DATA', res.data);
    if (res.data.deactivated) {
      throw new Error('deactivated');
    }
    if (res.data.expired) {
      throw new Error('expired');
    }
  },
};

const getters = {
  getAllEvidence(state, getters, rootState, rootGetters) {
    if (!state.data || !state.data.answersByControlID) return [];

    const sortedDomains = rootGetters['framework/sortedDomains'];
    const controlsByDomain = rootGetters['framework/controlsByDomain'];
    const evidenceArray = [];

    sortedDomains.forEach((domain) => {
      controlsByDomain[domain.domainID].forEach((controlID) => {
        const answer = state.data.answersByControlID[controlID];
        if (answer) {
          answer.evidence.forEach((filename) => {
            evidenceArray.push({
              filename,
              fileExtension: filename.split('.').pop(),
              controlIDs: [controlID],
              domainNames: [domain.name],
              // Todo: Add/Hardcode logic to include label where appropriate
              label: '',
              key: `${filename}-${controlID}`,
            });
          });
        }
      });
    });
    return evidenceArray;
  },
  getAllDocuments(state, getters, rootState, rootGetters) {
    if (!state.data || !state.data.documents || !state.data.answersByControlID) return [];

    const sortedDomains = rootGetters['framework/sortedDomains'];
    const controlsByDomain = rootGetters['framework/controlsByDomain'];

    return state.data.documents.map((document) => ({
      filename: document.name,
      fileExtension: document.extension,
      controlIDs: Object.values(state.data.answersByControlID)
        .filter((answer) => answer.documents.some((d) => d.name === document.name))
        .map((answer) => answer.controlID),
      domainNames: sortedDomains
        .filter((domain) =>
          controlsByDomain[domain.domainID].some((controlID) =>
            state.data.answersByControlID[controlID]?.documents.some(
              (d) => d.name === document.name,
            ),
          ),
        )
        .map((domain) => domain.name),
      key: document,
    }));
  },
  getFilteredAnswers(state, getters, rootState, rootGetters) {
    const answers = state.data?.answersByControlID;
    const realScopingAnswers = state.data?.scopingAnswersByDomainID;

    // Add fake "No" scoping answers if unanswered
    const selectedDomains = state.data?.selectedDomains;
    const domainsByID = rootGetters['framework/domainsByID'];
    const scopingAnswers = { ...realScopingAnswers };
    for (const domainID in domainsByID) {
      if (
        domainsByID[domainID].scopingQuestion &&
        (!selectedDomains || selectedDomains.length === 0 || selectedDomains.includes(domainID)) &&
        !scopingAnswers[domainID]
      ) {
        scopingAnswers[domainID] = { answer: false };
      }
    }

    if (!answers && !scopingAnswers) {
      return {};
    }

    const controlsByID = rootGetters['framework/controlsByID'];
    const searchValue = state.searchValue.toLowerCase();

    let filteredAnswers = {};
    if (answers) {
      filteredAnswers = Object.keys(answers)
        .filter((controlID) => {
          const controlQuestion = controlsByID[controlID].question.toLowerCase();
          const answerNotes = answers[controlID].notes.toLowerCase();
          return controlQuestion.includes(searchValue) || answerNotes.includes(searchValue);
        })
        .reduce((res, key) => {
          res[key] = answers[key];
          return res;
        }, {});
    }

    const filteredScopingAnswers = Object.keys(scopingAnswers)
      .filter(
        (domainID) =>
          domainsByID[domainID].scopingQuestion &&
          domainsByID[domainID].scopingQuestion.toLowerCase().includes(searchValue),
      )
      .reduce((res, key) => {
        res[key] = scopingAnswers[key];
        return res;
      }, {});

    return { ...filteredAnswers, ...filteredScopingAnswers };
  },
  getDomainsWithAnswers(state, getters, rootState, rootGetters) {
    const sortedDomains = rootGetters['framework/sortedDomains'];
    const controlsByDomain = rootGetters['framework/controlsByDomain'];

    const answers = getters.getFilteredAnswers;

    return sortedDomains.filter(
      (domain) =>
        controlsByDomain[domain.domainID].some((controlID) => !!answers[controlID]) ||
        !!answers[domain.domainID],
    );
  },
  getControlsByDomainWithAnswers(state, getters, rootState, rootGetters) {
    let domains = rootGetters['framework/sortedDomains'].map((domain) => ({
      ...domain,
      scopingAnswer: getters.getFilteredAnswers[domain.domainID],
      controls: rootGetters['framework/controlsByDomain'][domain.domainID].map((controlID) => {
        const control = rootGetters['framework/controlsByID'][controlID];

        return {
          letter: domain.letter,
          number: control.number,
          question: control.question,
          answer: getters.getFilteredAnswers[controlID],
          controlType: control.controlType,
          metadata: control.metadata,
        };
      }),
    }));

    // Remove controls without answers and domains without controls
    domains = domains.filter((domain) => {
      domain.controls = domain.controls.filter((control) => control.answer);
      return domain.controls.length || !!domain.scopingAnswer;
    });

    return domains;
  },
};

// TODO: replace with composables (0.5 days)
const profileSharingPublicStore = {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};

export default profileSharingPublicStore;
