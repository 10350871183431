import type { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: 'posts/:postId',
    name: 'client-community-post',
    component: () => import('./CommunityPostPage.vue'),
    meta: {}, // TODO: add community permissions (15 minutes)
    props: true,
  },
] satisfies RouteRecordRaw[];
