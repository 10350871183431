import type Permission from '@/composables/permissions/permissions';

export default {
  en: {
    unknown: 'Unknown',
    view: 'View',
    edit: 'Edit',
    admin: 'Admin',
    lead: 'Lead',
    supplier_view: 'View (Supplier Only)',
    client_view: 'View (Client Only)',
    supplier_edit: 'Edit (Supplier Only)',
    client_edit: 'Edit (Client Only)',
    federated_admin: 'Admin',
    federated_edit: 'Edit',
    federated_view: 'View',
    permissions: {
      ClientsFrameworkConfigApply: {
        title: 'Set Framework & Add-on Requirements',
        description: 'Users can set framework and add-on requirements for suppliers',
      },
      ClientsFrameworkConfigManage: {
        title: 'Manage Framework & Add-on Defaults',
        description: 'Users can set framework and add-on defaults',
      },
      ClientsApprovalChange: {
        title: 'Change Approval Statuses',
        description: 'User can update approval statuses.',
      },
      ClientsApprovalSettings: {
        title: 'Change Approval Settings',
        description: 'User can change the approval settings.',
      },
      ClientsConnectionViewsManage: {
        title: 'Manage Connection Views',
        description: 'User can make changes to connection views.',
      },
      ClientsConnectionsManage: {
        title: 'Manage Connection Requests',
        description: 'User can create and respond to connection requests.',
      },
      ClientsConnectionProductsManage: {
        title: 'Manage Product Connections',
        description: 'User can select and modify product connections with suppliers',
      },
      ClientsConnectionsRemove: {
        title: 'Remove Suppliers',
        description: 'User can remove supplier connections.',
      },
      ClientsConnectionsTogglePrivate: {
        title: 'Toggle Connection Privacy',
        description: 'User can toggle connection privacy.',
      },
      ClientsCustomPropertiesSettings: {
        title: 'Configure Custom Properties',
        description: 'Set ability to create and change custom properties.',
      },
      ClientsCustomPropertiesApply: {
        title: 'Apply Custom Properties',
        description: 'User can apply custom properties to suppliers.',
      },
      ClientsDiscussionsSend: {
        title: 'Respond to Discussions',
        description: 'User can create and respond to discussions.',
      },
      ClientsFilesDownload: {
        title: 'Preview and Download files',
        description: 'User can preview and download files.',
      },
      ClientsInsightsManage: {
        title: 'Manage Supplier Insights Questions',
        description: 'User can manage insight questions.',
      },
      ClientsInsightsView: {
        title: 'View Supplier Insights',
        description: 'User can view supplier insights.',
      },
      ClientsInvitesManage: {
        title: 'Manage Invites',
        description: 'User can send, resend and cancel invites.',
      },
      ClientsInvitesView: {
        title: 'View Invites',
        description: 'User can view invites.',
      },
      ClientsLabelsApply: {
        title: 'Apply Labels',
        description: 'Users can apply labels to suppliers.',
      },
      ClientsLabelsManage: {
        title: 'Configure Labels',
        description: 'User can create and edit labels.',
      },
      ClientsNetworkSettings: {
        title: 'Change Network Contribution Settings',
        description: 'User can edit network contribution settings.',
      },
      ClientsNetworkView: {
        title: 'View Network Visualisations',
        description: 'User can view network visualisations.',
      },
      ClientsOverridesApply: {
        title: 'Apply Overrides',
        description: 'User can apply overrides to suppliers.',
      },
      ClientsPoliciesManage: {
        title: 'Manage Policies',
        description: 'User can create and edit policies.',
      },
      ClientsRemediationsManage: {
        title: 'Manage Remediations',
        description: 'User can create and respond to remediations.',
      },
      ClientsReportingView: {
        title: 'View Reporting',
        description: 'User can view reports.',
      },
      ClientsRisksManage: {
        title: 'Manage Risks',
        description: 'User can create and edit risks.',
      },
      ClientsRisksNotesAdd: {
        title: 'Add Risk Notes',
        description: 'User can add notes to risks.',
      },
      ClientsRisksSettings: {
        title: 'Change Risks Settings',
        description: 'User can change risk settings.',
      },
      ClientsRisksView: {
        title: 'View Risks',
        description: 'User can view risks.',
      },
      ClientsSuppliersNotesManage: {
        title: 'Manage Private Notes',
        description: 'User can create, edit and delete private notes.',
      },
      ClientsSuppliersNotesView: {
        title: 'View Private Notes',
        description: 'User can view private notes.',
      },
      ClientsAssignedUsersApply: {
        title: 'Assign Users',
        description: 'User can assign users to suppliers.',
      },
      ClientsSuppliersView: {
        title: 'View Suppliers',
        description: 'User can view suppliers list and profiles.',
      },
      ClientsSuppliersManage: {
        title: 'Create Suppliers',
        description: 'User can create suppliers profiles.',
      },
      ClientsTagsApply: {
        title: 'Apply Tags',
        description: 'Users can apply tags to suppliers.',
      },
      ClientsCommunitiesMessageboardView: {
        title: 'View Community Message Board',
        description: 'User can view the feed and post pages in communities.',
      },
      ClientsCommunitiesMessageboardPost: {
        title: 'Post and Reply in Community Message Board',
        description: 'User can post in communities and reply to posts.',
      },
      ClientsCommunitiesMessageboardManage: {
        title: 'Manage Community Message Board Posts',
        description:
          'User can delete a post or a reply that was made by another user in their organisation.',
      },
      ClientsCommunitiesSignalsView: {
        title: 'View Communities Signals',
        description: 'User can view community related signals on supplier profiles.',
      },
      ClientsCommunitiesInvitesManage: {
        title: 'Manage Community Invites',
        description: 'User can manage community invites.',
      },
      ClientsExternalMonitoringManage: {
        title: 'Manage External Monitoring Assets',
        description: 'Users can add, modify and add context to assets that are being scanned',
      },
      ClientsExternalMonitoringView: {
        title: 'View External Asset Results',
        description: 'Users can view results from external monitoring asset scans',
      },
      FederatedCustomPropertiesSettings: {
        title: 'Configure Custom Properties',
        description: 'User can create and edit custom properties.',
      },
      FederatedCustomPropertiesApply: {
        title: 'Apply Custom Properties',
        description: 'User can apply custom property values to sub-entities.',
      },
      FederatedInvitesManage: {
        title: 'Manage Invites',
        description: 'User can send and cancel invites.',
      },
      FederatedInvitesView: {
        title: 'View Invites',
        description: 'User can view invites.',
      },
      FederatedLabelsApply: {
        title: 'Apply Labels',
        description: 'User can apply labels to sub-entities.',
      },
      FederatedLabelsManage: {
        title: 'Manage Labels',
        description: 'User can create and edit labels.',
      },
      FederatedNetworkView: {
        title: 'View Network Visualisations',
        description: 'User can view network visualisations.',
      },
      FederatedRisksManage: {
        title: 'Manage Risks',
        description: 'User can create and edit risks.',
      },
      FederatedRisksNotesAdd: {
        title: 'Add Risk Notes',
        description: 'User can add notes to risks.',
      },
      FederatedRisksSettings: {
        title: 'Change Risks Settings',
        description: 'User can change risk settings.',
      },
      FederatedRisksView: {
        title: 'View Risks',
        description: 'User can view risks.',
      },
      FederatedSubEntitiesView: {
        title: 'View Sub-entities',
        description: 'User can view sub-entities.',
      },
      SettingsApiKeysManage: {
        title: 'Manage API Keys',
        description: 'User can create and delete API keys.',
      },
      SettingsDiscussionSeen: {
        title: 'Change Discussion Seen Setting',
        description: 'User can change the discussion seen setting.',
      },
      SettingsManageMode: {
        title: 'Enable Platform Modes',
        description: 'User can turn on different platform modes.',
      },
      SettingsOrgManage: {
        title: 'Edit Organisation',
        description: 'User can edit organisation details.',
      },
      SettingsSsoManage: {
        title: 'Manage SSO Settings',
        description: 'User can change SSO settings.',
      },
      SettingsUsersManage: {
        title: 'Manage Users',
        description: 'User can create, edit and delete users.',
      },
      SettingsRolesManage: {
        title: 'Manage Roles',
        description: 'User can create, edit and delete roles.',
      },
      SuppliersAssessmentEdit: {
        title: 'Answer Assessment Questions',
        description: 'User can answer assessment questions, change notes and attachments.',
      },
      SuppliersAssessmentSubmit: {
        title: 'Submit Assessment',
        description: 'User can submit the assessment when complete.',
      },
      SuppliersClientsView: {
        title: 'View Clients',
        description: 'User can view the clients list and profiles.',
      },
      SuppliersExternalMonitoringView: {
        title: 'View External Monitoring Results',
        description: 'Users can view results from external monitoring asset scans',
      },
      SuppliersExternalMonitoringManage: {
        title: 'Manage External Monitoring Assets',
        description: 'Users can add, modify and add context to assets that are being scanned',
      },
      SuppliersConnectionsManage: {
        title: 'Manage Connection Requests',
        description: 'User can create and respond to connection requests.',
      },
      SuppliersConnectionsRemove: {
        title: 'Remove Clients',
        description: 'User can remove client connections.',
      },
      SuppliersMergeRequestsManage: {
        title: 'Manage Merge Requests',
        description: 'User can manage merge requests.',
      },
      SuppliersDiscussionsSend: {
        title: 'Respond to Discussions',
        description: 'User can create and respond to discussions.',
      },
      SuppliersEmergingThreatsRespond: {
        title: 'Respond to Emerging Threats',
        description: 'User can respond to emerging threats.',
      },
      SuppliersFilesDownload: {
        title: 'Preview and Download files',
        description: 'User can preview and download files.',
      },
      SuppliersProfileSharingManage: {
        title: 'Manage Profile Sharing Links',
        description: 'User can create, edit and delete profile sharing links.',
      },
      SuppliersProfileSharingView: {
        title: 'View Profile Sharing Links',
        description: 'User can view profile sharing links.',
      },
      SuppliersRemediationsRespond: {
        title: 'Respond to Remediations',
        description: 'User can respond to remediations.',
      },
      ZeusCommunitiesView: {
        title: 'View Communities',
        description: 'User can view all communities on the platform.',
      },
      ZeusCommunitiesManage: {
        title: 'Manage Communities',
        description: 'User can create, edit and delete communities.',
      },
      ZeusCommunitiesMembers: {
        title: 'Manage Community Members',
        description: 'User can add and remove members from communities.',
      },
    } satisfies Record<keyof typeof Permission, { title: string; description: string }>,
    deleteModal: {
      title: 'Delete Role',
      description: 'Are you sure you want to delete the {roleName} role?',
      close: 'Close',
      delete: 'Delete',
      replacementRole: 'Replacement Role',
      replacementDescription:
        'There are users assigned to this role which require a replacement role to be assigned to them.',
      replacementRoleEmpty: 'Please select a replacement role',
      error: 'Failed to delete role',
      replacementRoleRequired: 'Please select a replacement role',
    },
  },
};
