export default {
  en: {
    fetchError: 'Failed fetching client notes.',
    fetchRetry: 'Retry',
    emptyNotePlaceholder: 'Leave a note...',
    emptyError: 'Notes cannot be empty.',
    add: 'Add Note',
    addError: 'Failed to add note, please try again.',
    maxLengthError: 'Note is too long!',
    save: 'Save',
    saveError: 'Failed to save note, please try again.',
    cancel: 'Cancel',
    visibleLabel: 'Private notes are only visible to {0}.',
    pinTooltip: 'Pin note',
    unpinTooltip: 'Un-pin note',
    editTooltip: 'Edit note',
    pinFailed: 'Failed to pin note.',
    unpinFailed: 'Failed to un-pin note.',
    deleteTooltip: 'Delete note',
    deleteFailed: 'Failed to delete note.',
    emptyPlaceholder: 'No notes yet on this supplier, create one above.',
    deleteConfirmTitle: 'Are you sure you want to delete this note?',
    deleteConfirmDescription: 'This action cannot be undone.',
  },
};
