import { createApp } from 'vue';
import { createPinia } from 'pinia';

import * as Sentry from '@sentry/vue';
import VueAnnouncer from '@vue-a11y/announcer';

import RiskLedgerPlugin from '@/plugins/risk-ledger';
import { logError } from '@/libs/analytics';

import store from '@/store';
import { storeInjectionKey } from '@/store/useStore';
import router from '@/routes';
import App from './App.vue';

import '@/styles/all';

const app = createApp(App);

app.use(VueAnnouncer, { router });

app.use(createPinia());

app.use(router);

app.use(store, storeInjectionKey);

app.use(RiskLedgerPlugin);

app.config.errorHandler = (error: unknown) => {
  if (import.meta.env.MODE !== 'prod') {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  logError(error);
};

Sentry.init({
  app,
  dsn: 'https://65661bf8d5fa04ddee40133c122dbda4@o4507701844901888.ingest.de.sentry.io/4507980359663696',
  integrations: [
    Sentry.replayIntegration({
      // Mask values of <input> elements. By default, masks text with *
      // @see: https://docs.sentry.io/platforms/javascript/session-replay/privacy/#masking
      maskAllInputs: true,

      maskAllText: false,
      blockAllMedia: false,

      block: ['[data-private]'],
    }),
    // Need this to enable Canvas replays
    Sentry.replayCanvasIntegration(),
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  enabled: import.meta.env.MODE === 'prod',
  environment: import.meta.env.MODE,

  // @see: https://docs.sentry.io/platforms/javascript/guides/vue/features/component-tracking/#trackcomponents
  trackComponents: true,
});

router.isReady().then(() => {
  app.mount('#app');
});
