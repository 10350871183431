import { createNamespacedHelpers } from 'vuex';
import appliedLabels from './appliedLabelsStore';

export const { mapState, mapActions, mapGetters } = createNamespacedHelpers('labels');

// TODO: replace with composables (0.5 days)
const labelsStore = {
  namespaced: true,
  modules: {
    appliedLabels,
  },
};

export default labelsStore;
