import type { RouteRecordRaw } from 'vue-router';
import {
  requireAnyType,
  requireClient,
  requireNotFederated,
  requireSupplier,
} from '@/routes/guards/orgtype';
import Permission from '@/composables/permissions/permissions';
import { requireAnyFlag } from '@/routes/guards/flag';
import { FeatureFlag } from '@/composables/feature-flags';

export default [
  {
    path: 'settings',
    children: [
      {
        path: '',
        name: 'common-settings',
        component: () => import('./Settings.vue'),
        redirect: { name: 'settings-account' },
        children: [
          {
            path: 'account',
            name: 'settings-account',
            component: () => import('./components/Account.vue'),
          },
          {
            path: 'notifications',
            name: 'settings-notifications',
            beforeEnter: requireNotFederated,
            component: async () => {
              const m = await import('@/modules/notificationSettings');
              return m.NotificationSettings;
            },
          },
          {
            path: 'organisation',
            name: 'settings-organisation',
            component: () => import('./components/OrgSettings.vue'),
          },
          {
            path: 'admin',
            component: () => import('./components/ManageUsersAndRoles.vue'),
            children: [
              { path: '', name: 'settings-admin', redirect: { name: 'settings-admin-users' } },
              {
                path: 'users',
                name: 'settings-admin-users',
                component: () => import('./components/ManageUsers.vue'),
              },
              {
                path: 'roles',
                name: 'settings-admin-roles',
                component: () => import('./components/ManageRoles.vue'),
              },
            ],
          },
          {
            path: 'sso',
            name: 'settings-sso',
            component: async () => {
              const m = await import('@/modules/authSSOSettings');
              return m.SSOSettings;
            },
          },
          {
            path: 'approval',
            name: 'settings-approval',
            beforeEnter: [requireClient],
            component: () => import('@/modules/approval/components/ApprovalConfig.vue'),
            meta: {
              permissions: [Permission.ClientsApprovalSettings],
            },
          },
          {
            path: 'labels',
            name: 'settings-labels',
            beforeEnter: [requireAnyType(['client', 'federated'])],
            component: () => import('@/modules/labels/components/LabelList.vue'),
            meta: {
              permissions: [Permission.ClientsLabelsManage, Permission.FederatedLabelsManage],
            },
          },
          {
            path: 'properties',
            name: 'settings-custom-properties',
            beforeEnter: [requireAnyType(['client', 'federated'])],
            component: async () => {
              const m = await import('@/modules/customProperties');
              return m.CustomPropertiesSettings;
            },
            meta: {
              permissions: [
                Permission.ClientsCustomPropertiesSettings,
                Permission.FederatedCustomPropertiesSettings,
              ],
            },
          },
          {
            path: 'framework',
            name: 'settings-framework-defaults',
            beforeEnter: [
              requireAnyFlag(FeatureFlag.FrameworkLevels, FeatureFlag.FrameworkAddOns),
              requireClient,
            ],
            component: () => import('./components/FrameworkDefaultConfig.vue'),
            meta: {
              permissions: [Permission.ClientsFrameworkConfigManage],
            },
          },
          {
            path: 'network',
            name: 'settings-network',
            beforeEnter: [requireClient],
            component: async () => {
              const m = await import('@/modules/clientNetwork');
              return m.NetworkConfig;
            },
            meta: {
              permissions: [Permission.ClientsNetworkSettings],
            },
          },
          {
            path: 'network-community',
            name: 'settings-network-community',
            beforeEnter: [requireClient],
            component: () => import('./components/NetworkAndCommunity.vue'),
            meta: {
              permissions: [Permission.ClientsNetworkSettings],
            },
          },
          {
            path: 'api',
            name: 'settings-api',
            beforeEnter: [requireClient],
            component: async () => {
              const m = await import('@/modules/apiPublic');
              return m.PublicAPISettings;
            },
            meta: {
              permissions: [Permission.SettingsApiKeysManage],
            },
          },
          {
            path: 'discussions',
            name: 'settings-discussions',
            beforeEnter: [
              requireClient, // also guards from federated org, since federated can't be client
            ],
            component: async () => {
              const m = await import('@/modules/discussions');
              return m.DiscussionsConfig;
            },
            meta: {
              permissions: [Permission.SettingsDiscussionSeen],
            },
          },
          {
            path: 'risks',
            name: 'settings-risks',
            component: () => import('@/modules/risks/RisksSettings.vue'),
            meta: {
              permissions: [Permission.ClientsRisksSettings, Permission.FederatedRisksSettings],
            },
          },
          {
            path: 'products',
            name: 'settings-products',
            beforeEnter: [requireAnyFlag(FeatureFlag.Products), requireSupplier],
            component: () => import('@/modules/products/ProductsSettings.vue'),
            meta: {
              permissions: [Permission.SuppliersAssessmentEdit],
            },
          },
        ],
      },
      // These routes are separate so that they don't have the sidebar or padding
      {
        path: 'admin/roles/new',
        name: 'settings-admin-new-role',
        component: () => import('./components/AddRole.vue'),
        meta: {
          permissions: [Permission.SettingsRolesManage],
        },
      },
      {
        path: 'admin/roles/:id',
        name: 'settings-admin-edit-role',
        component: () => import('./components/EditRole.vue'),
        props: true,
      },
    ],
  },
] satisfies RouteRecordRaw[];
