export default {
  en: {
    connect: 'Connect',
    connected: {
      full: 'Connected.',
      less: '',
    },
    view: 'View',
    failed: 'Request failed',
    cancel: 'Cancel',
    accept: 'Accept',
    reject: 'Reject',
    restricted: 'Temporarily restricted',
    restrictedTooltip:
      "You're unable to send Connection Requests to other organisations until you have completed your assessment.",
    clientReceive: {
      full: 'This supplier would like to connect with you',
      less: 'Request received.',
    },
    supplierReceive: {
      full: 'This client would like to connect with you',
      less: 'Request received.',
    },
    clientSend: {
      full: 'You requested to be their Client.',
      less: 'Request sent.',
    },
    supplierSend: {
      full: 'You requested to be their Supplier.',
      less: 'Request sent.',
    },
    verifyMessage:
      'To verify your connection request, please include the name and email address of your point of contact at {0}:',
    contactName: 'Your point of contact name...',
    contactEmail: 'Your point of contact email...',
    messagePlaceholder: 'Your message to {0}...',
    messageAutofillShort:
      'Hi - {senderOrg} would like to connect with you to review your Risk Ledger assessment. Thanks, {senderUser} ({senderEmail})',
    messageAutofillLong:
      'Hi - {senderOrg} would like to connect with you to review your Risk Ledger assessment. We emailed {contact} on [date] letting them know of this request. Thanks, {senderUser} ({senderEmail})',
    contactDetails: '{contactName} ({contactEmail})',
    addDate: 'Please add the date of contact or modify the message.',
    invalidEmail: 'Please enter a valid email address.',
    send: 'Send Request',
    sendFailed: 'Failed to send connection request.',
    primerEmail: {
      desc: 'I confirm a {primerEmail} has been sent to this supplier.',
      link: 'Primer Email',
    },
    cancelModal: {
      title: 'Are you sure you want to cancel the connection request for {0}?',
      cancel: 'Yes, Cancel Connection Request',
      close: 'No',
    },
    rejectModal: {
      title: 'Are you sure you want to reject the connection request from {orgName}?',
      reject: 'Reject',
      cancel: 'Cancel',
      reasonQuestion: 'Why are you rejecting this connection request?',
      reasonPlaceholder: 'Rejection reason...',
      error: 'Failed rejecting connection request. Please try again.',
    },
  },
};
