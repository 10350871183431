// Vuex & Store Entity Imports
import { createNamespacedHelpers } from 'vuex';
import activityItems from './activityItemsStore';

// Parent Store Module
export const { mapState, mapActions, mapGetters } = createNamespacedHelpers('activityFeed');

// TODO: replace with composables (1 day)
const activityFeedStore = {
  namespaced: true,
  modules: {
    activityItems,
  },
};

export default activityFeedStore;
