import type { InjectionKey } from 'vue';
// eslint-disable-next-line local-rules/no-vuex-store
import { useStore as baseUseStore, type Store } from 'vuex';
import type { State as AssessmentState } from '@/modules/assessment/store/mutations';

export type State = {
  assessment: AssessmentState;
  [name: string]: any;
};

export const storeInjectionKey: InjectionKey<Store<State>> = Symbol('vuex-store');

// A partially typed `useStore`
export const useStore = () => baseUseStore(storeInjectionKey);
