import type { Getter, GetterTree, MutationTree, Module } from 'vuex';
import type { FileModel } from '@/types/models/file';
import { getFileStatus as getFileStatusUtil } from './utils';

/**
 * This store is now read only, and has been largely replaced by the composables
 * found in composables.ts. The only reason this is still around is so that
 * components using the getFilename / getFileStatus etc getters still work until
 * they're updated.
 *
 * TODO: remove completely (0.5 days)
 */
const initialState = () => ({
  byID: {} as Record<string, FileModel>,
});
type State = ReturnType<typeof initialState>;

const mutations: MutationTree<State> = {
  FLUSH(state) {
    Object.assign(state, initialState());
  },
  SET_FILE(state, file) {
    state.byID[file.id] = file;
  },
  SET_FILES(state, filesByID) {
    Object.assign(state.byID, filesByID);
  },
};

// Depending on which API response we're dealing with, a file object may have its file ID
// in differently-named fields; this helper makes sure to return a file ID if the file has one.
//
// getFileID doesn't need to be a store getter, but it's been kept close to other dependent helpers below it.
const getFileID: Getter<State, unknown> = () => (file: { id: string } | { uploadID: string }) =>
  'id' in file
    ? file.id // how the GetUploads API returns the ID for each file
    : file.uploadID; // how other areas of RL returns the ID for each file (e.g. notes, discussions, answers)

// A file object's name will either be in the store (along with its other info) or it'll be in the file object itself.
// If the file has just been selected/dropped by the user then the file name will be inside the file object.
// If the file has already been uploaded then the file's name (and other info) is retrieved and managed by this store;
// the store must wait for the API to return the file name (and other info), so there may be a period of time where the
// file name is an empty string.
const getFilename: Getter<State, unknown> = (state, getters) => (file: FileModel) => {
  if (file.filename) return file.filename;
  const storeFile = state.byID[getters.getFileID(file)];
  return storeFile ? storeFile.filename : '';
};

export const getFileStatus: Getter<State, unknown> =
  (state, getters) => (file: FileModel | { id: string }) => {
    // We get it from the store again as loads of places are calling getFileStatus({ id }) :(
    const storeFile = state.byID[getters.getFileID(file)];
    if (!storeFile) return null;
    return getFileStatusUtil(storeFile);
  };

const getFileSize: Getter<State, unknown> = (state, getters) => (file: FileModel) => {
  const fileID = getters.getFileID(file);
  return state.byID[fileID]?.size;
};

const getters: GetterTree<State, unknown> = {
  getFileID,
  getFilename,
  getFileStatus,
  getFileSize,
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
} satisfies Module<State, unknown>;
