// Vuex & Store Entity Imports
import { createNamespacedHelpers } from 'vuex';
import riskNotes from './riskNotesStore';

// Parent Store Module
export const { mapState, mapActions, mapGetters } = createNamespacedHelpers('risks');

// TODO: replace with composables (0.5 days)
const risksStore = {
  namespaced: true,
  modules: {
    riskNotes,
  },
};

export default risksStore;
