import { createNamespacedHelpers } from 'vuex';

import { state, mutations } from './mutations';
import { actions } from './actions';

export const { mapState, mapActions } = createNamespacedHelpers('invite');

// TODO: replace with composables (1 day)
const inviteStore = {
  namespaced: true,
  actions,
  state,
  mutations,
};

export default inviteStore;
