import api from '../api';

const setSupplierOrgID = ({ commit }, id) => {
  commit('SET_SUPPLIER_ORGID', id);
};

const setClientOrgID = ({ commit }, id) => {
  commit('SET_CLIENT_ORGID', id);
};

// Client
const getSupplierAssessmentWithOrgID = async ({ commit }, id) => {
  const res = await api.getSupplierAssessment(id);
  commit('SET_SUPPLIER_ASSESSMENT', res.data);
};

const getClientAssessmentWithOrgID = async ({ commit }, id) => {
  const res = await api.getClientAssessment(id);
  commit('SET_CLIENT_ASSESSMENT', res.data);
};

const setSupplierTags = async ({ commit }, { connID, tags }) => {
  const res = await api.setSupplierTags(connID, tags);
  commit('SET_SUPPLIER_TAGS', res.data);
};

// Notes
const setClientNotes = async ({ commit }, { connectionID, controlID, notes }) => {
  const res = await api.setClientNotes(connectionID, controlID, notes);
  commit('SET_CLIENT_NOTES', res.data);
};

// Remediation
const requestRemediation = async ({ commit }, { connectionID, controlID, deadline, reason }) => {
  const res = await api.requestRemediation(connectionID, controlID, deadline, reason);
  commit('SET_REMEDIATION', res.data);
};

const cancelRemediation = async ({ commit, state }, controlID) => {
  await api.cancelRemediation(state.remediation[controlID].remediationID);
  commit('REMOVE_REMEDIATION', controlID);
};

const acceptRemediation = async ({ commit, state }, controlID) => {
  await api.acceptRemediation(state.remediation[controlID].remediationID);
  commit('REMOVE_REMEDIATION', controlID);
};

const respondRemediation = async ({ commit, state }, controlID) => {
  const res = await api.respondRemediation(state.remediation[controlID].remediationID);
  commit('SET_REMEDIATION', res.data);
};

const rejectRemediation = async ({ commit, state }, controlID) => {
  const res = await api.rejectRemediation(state.remediation[controlID].remediationID);
  commit('SET_REMEDIATION', res.data);
};

// Overrides
const applyOverride = async ({ commit, state }, { controlID, reason, type }) => {
  const res = await api.applyOverride(state.supplierOrgID, controlID, reason, type);
  commit('APPLY_OVERRIDE', res.data);
};

const applyMultipleOverrides = async ({ state, dispatch }, { controlIDs, reason, type }) => {
  if (type === 'exemption') {
    controlIDs = controlIDs.filter((id) => !state.complianceByControlID[id]);
  }
  if (type === 'noncompliance') {
    controlIDs = controlIDs.filter((id) => !!state.complianceByControlID[id]);
  }
  // apply overrides in parallel, then wait for all to finish
  const updates = [];
  for (const controlID of controlIDs) {
    updates.push(dispatch('applyOverride', { controlID, reason, type }));
  }
  await Promise.all(updates);
};

const removeOverride = async ({ commit, state }, controlID) => {
  await api.removeOverride(state.supplierOrgID, controlID);
  commit('REMOVE_OVERRIDE', controlID);
};

// Filters
const setAssessmentFilters = ({ commit }, filters) => {
  commit('SET_ASSESSMENT_FILTERS', filters);
};

const clearAssessmentFilters = ({ commit }) => {
  commit('CLEAR_ASSESSMENT_FILTERS');
};

const setEvidenceFilters = ({ commit }, filters) => {
  commit('SET_EVIDENCE_FILTERS', filters);
};

// Clear store
const clearProfileStore = ({ commit }) => {
  commit('CLEAR_STATE');
};

// Risks

const addControlRisk = ({ state, commit }, { controlID, risk }) => {
  const risksByControlID = { ...state.risksByControlID };
  if (risksByControlID[controlID]?.length > 0) risksByControlID[controlID].unshift(risk);
  else risksByControlID[controlID] = [risk];
  commit('SET_CONTROL_RISKS', risksByControlID);
};

const updateControlRisk = ({ state, commit }, { controlID, risk }) => {
  const risksByControlID = { ...state.risksByControlID };
  const i = risksByControlID[controlID].findIndex((r) => r.id === risk.id);
  risksByControlID[controlID][i] = risk;
  commit('SET_CONTROL_RISKS', risksByControlID);
};

const closeControlRisk = ({ state, commit }, { controlID, risk }) => {
  const risksByControlID = { ...state.risksByControlID };
  const i = risksByControlID[controlID].findIndex((r) => r.id === risk.id);
  risksByControlID[controlID].splice(i, 1);
  commit('SET_CONTROL_RISKS', risksByControlID);
};

// Emerging threats

const setEmergingThreats = ({ commit }, emergingThreatsByID) => {
  commit('SET_EMERGING_THREATS', emergingThreatsByID);
};

export const actions = {
  setSupplierOrgID,
  setClientOrgID,
  getClientAssessmentWithOrgID,
  getSupplierAssessmentWithOrgID,
  setSupplierTags,
  setClientNotes,
  requestRemediation,
  cancelRemediation,
  acceptRemediation,
  respondRemediation,
  rejectRemediation,
  applyOverride,
  applyMultipleOverrides,
  removeOverride,
  setAssessmentFilters,
  clearAssessmentFilters,
  clearProfileStore,
  setEvidenceFilters,
  addControlRisk,
  updateControlRisk,
  closeControlRisk,
  setEmergingThreats,
};
