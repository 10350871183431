import { createNamespacedHelpers } from 'vuex';

import api from './modules/api';
import countries from './modules/countries';
import signup from './modules/signup';
import cognitoUser from './modules/cognitoUser';

import * as apiActions from './actions/api';
import * as signupActions from './actions/signup';
import * as cognitoUserActions from './actions/cognitoUser';

export const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers('legacy');

export default {
  namespaced: true,
  modules: {
    api,
    countries,
    signup,
    cognitoUser,
  },
  actions: {
    ...apiActions,
    ...signupActions,
    ...cognitoUserActions,
  },
};
