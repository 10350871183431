import type { RouteRecordRaw } from 'vue-router';

export default [
  {
    // Deprecated.
    // Maintaining for this path for backwards compatibility.
    // TODO later: delete this. (15 minutes)
    path: '/shared',
    name: 'shared',
    redirect: { name: 'shared-assessment' },
  },
] satisfies RouteRecordRaw[];
