// Vuex & Store Entity Imports
import { createNamespacedHelpers } from 'vuex';
import links from './linksStore';

// Parent Store Module
export const { mapActions, mapState, mapGetters, mapMutations } =
  createNamespacedHelpers('profileSharingLinks');

const initialState = () => ({
  displaySidePanel: false,
  displayUpdateSidePanel: false,
  updateLinkID: '',
});

const mutations = {
  SET_DISPLAY_SIDEPANEL(state, val) {
    state.displaySidePanel = val;
  },
  SET_DISPLAY_UPDATE_SIDEPANEL(state, { display, linkID }) {
    state.displayUpdateSidePanel = display;
    state.updateLinkID = linkID;
  },
};

// TODO: replace with composables (1 day)
const profileSharingLinksStore = {
  namespaced: true,
  modules: { links },
  state: initialState(),
  mutations,
};

export default profileSharingLinksStore;
