import { createNamespacedHelpers } from 'vuex';

import { state, mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

export const { mapState, mapActions, mapGetters } = createNamespacedHelpers('emergingThreats');

// TODO: replace with composables (1 day)
const emergingThreatsStore = {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
};

export default emergingThreatsStore;
